import React, { useState } from 'react';
import {
  Button, TextField, FormControl, InputLabel, Select, MenuItem, Box,
  Grid, Typography, FormControlLabel, Checkbox, Link, Alert, Container,
  FormHelperText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStatus } from '../providers/MsgStatusProvider'; // Assuming you have this provider

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

/**
 * Register component for user registration
 * @component
 */
function Register() {
    const navigate = useNavigate();
    const { setStatusMessage, setStatusType, setIsAlertOpen } = useStatus();
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        connection: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
        if (!agreeToTerms) newErrors.agreeToTerms = 'You must agree to the terms and conditions';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        const url = `${process.env.REACT_APP_API_BASE_URL}user/register/email`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: formData.email,
                })
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('token', data.token);
                setStatusMessage('Registration successful');
                setStatusType('success');
                setIsAlertOpen(true);
                navigate('/check-email');
            } else {
                throw new Error(data.message || 'Registration failed');
            }
        } catch (error) {
            console.error('Registration error:', error);
            setStatusMessage(error.message);
            setStatusType('error');
            setIsAlertOpen(true);
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h3" gutterBottom>
                    Join Solo Social
                </Typography>
                <StyledForm onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={agreeToTerms}
                                        onChange={(e) => setAgreeToTerms(e.target.checked)}
                                        name="agreeToTerms"
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        By creating an account you agree to the{" "}
                                        <Link href="/terms-and-conditions" target="_blank" rel="noopener">
                                            Terms and Conditions
                                        </Link>{" "}
                                        and{" "}
                                        <Link href="/privacy-policy" target="_blank" rel="noopener">
                                            Privacy Policy
                                        </Link>
                                    </Typography>
                                }
                            />
                            {errors.agreeToTerms && (
                                <FormHelperText error>{errors.agreeToTerms}</FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Register
                    </Button>
                </StyledForm>
            </Box>
        </Container>
    );
}

export default Register;