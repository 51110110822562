import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, TextField, Button, Grid } from '@mui/material';
import { useStatus } from "../providers/MsgStatusProvider";
import { useNavigate } from "react-router";

const FixedCTAContainer = styled(Box)(({ theme }) => ({
  zIndex: 1000,
  width: '90%',
  maxWidth: 1360,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
  position: 'fixed',
  top: 24,
  left: 0,
  right: 0,
}));

const FixedCTA = styled(Button)(({ theme }) => ({
  zIndex: 500,
  color: '#fff',
  letterSpacing: '0.8px',
  textTransform: 'uppercase',
  backgroundColor: '#0a0a0a',
  border: '2px solid #000',
  borderRadius: 8,
  alignItems: 'center',
  padding: '12px 20px 12px 18px',
  fontSize: 14,
  fontWeight: 700,
  textDecoration: 'none',
  transition: 'all 0.4s',
  display: 'flex',
  position: 'fixed',
  top: 24,
  right: '5vw',
  boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
  '&:hover': {
    transform: 'translate(0, -2px)',
    boxShadow: '0 1px 3px rgba(0,0,0,0.15)',
  },
}));

const PageWrapper = styled(Box)({
  overflow: 'hidden',
  transform: 'translate(0)',
});

const StyledHero = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  paddingRight: theme.spacing(7),
  paddingLeft: theme.spacing(7),
  borderRadius: theme.spacing(2),
  height: '75vh',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    padding: theme.spacing(4),
  },
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: '5rem',
  marginTop: 0,
  marginBottom: 24,
  position: 'relative',
  fontFamily: 'Uncutsans, sans-serif',
  fontWeight: 700,
  lineHeight: '80px',
  letterSpacing: '-2.4px',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: 0,
    width: 100,
    height: 4,
    backgroundColor: theme.palette.common.black,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem',
    lineHeight: '56px',
  },
}));

const HeroSubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[300],
  fontSize: '1.5rem',
  lineHeight: 1.5,
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
  },
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const FeatureGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}));

const FeatureItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const { setStatusType, setStatusMessage, setIsAlertOpen } = useStatus();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_API_BASE_URL}user/register/email`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (response.ok) {
        setIsAlertOpen(true);
        setStatusMessage(data.message);
        setStatusType('success');
        navigate('/check-email');
      } else {
        throw new Error(data.message || 'Registration failed');
      }
    } catch (error) {
      setIsAlertOpen(true);
      setStatusMessage(error.message);
      setStatusType('error');
    }
  };

  return (
    <PageWrapper>
      <Container id="hey">
        <StyledHero container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h6" component="h6" gutterBottom sx={{ backgroundColor: '#f5f2eb', display: 'inline-block', padding: '10px', borderRadius: '12px' }}>
              In Alpha
            </Typography>
            <MainTitle variant="h1">Solo Social</MainTitle>
            <HeroSubTitle variant="h3">
              We help business owners, event organizers & content creators craft,
              schedule, & amplify online content from one place.
            </HeroSubTitle>
          </Grid>
        </StyledHero>

        <Box id="get-started" mt={4}>
          <StyledForm onSubmit={handleSubmit}>
            <Typography variant="h6" component="label" htmlFor="email">Email Address</Typography>
            <StyledTextField
              fullWidth
              id="email"
              name="email"
              placeholder="Your Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Join
            </StyledButton>
            <Typography variant="caption" display="block" mt={1}>
              By signing up you agree to the terms and conditions & privacy policy.
              We may send you some casual updates, but promise not to overdo it!
            </Typography>
          </StyledForm>
        </Box>

        <FeatureGrid container spacing={4}>
          {[
            { title: 'Online Editor', content: 'Access our online design tool anywhere you have internet access. The editor is packed with ready to use templates that makes editing easier to build branded templates.' },
            { title: 'Image Automation', content: 'Save yourself hours by create new images from your personalized templates in seconds through on simple form. Attach your images to social media post and go.' },
            { title: 'Social Media Automation', content: 'Easily jump between drafting post to go out in the future with posting instantly from one simple interface. Leverage our calendar view to review your post.' },
            { title: 'Easy and reliable', content: 'We\'ve focused on a simplified user experiences so we don\'t become another tool you have to learn. We remove the technical overhead so you can be creative.' },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FeatureItem>
                <Typography variant="h5" component="h3" gutterBottom>{feature.title}</Typography>
                <Typography variant="body1">{feature.content}</Typography>
              </FeatureItem>
            </Grid>
          ))}
        </FeatureGrid>

        <Box my={8} textAlign="center">
          <Typography variant="h4" gutterBottom>
            Stay consistent. Stay on brand.
          </Typography>
          <Typography variant="body1" paragraph>
            Managing consistent, engaging online content is a challenge. With numerous platforms and
            ever-evolving audience preferences, it's tough to keep up.
          </Typography>
        </Box>

        <Box my={8} textAlign="center" bgcolor="grey.100" p={4} borderRadius={2}>
          <Typography variant="h4" gutterBottom>
            Don't let finding time for effective online marketing fall by the wayside.
          </Typography>
          <Typography variant="body1">
            With Solo Social, marketing efficiency is no longer out of reach. Our platform streamlines
            content creation, scheduling, and analysis, giving you time back to focus on your business.
          </Typography>
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default LandingPage;