import React, {useEffect, useMemo, useState} from 'react';
// import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import dayjs from 'dayjs'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, dayjsLocalizer} from 'react-big-calendar'
import {useAuth} from "../providers/AuthProvider";
import styled from "@emotion/styled";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import {Link} from "react-router-dom";

const localizer = dayjsLocalizer(dayjs);

const MessageWrapper = styled('div')(({theme}) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey["80"],
    color: theme.palette.grey["100"],
    display: 'flex',
    borderRadius: theme.spacing(2),
    // height: '80vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

function DashboardPage() {
    // const classes = useStyles();
    const auth = useAuth();
    const [templates, setTemplates] = useState([]);
    const [post, setPosts] = useState([]);
    const posts_cal_events = auth.user[0].posts_cal_events;
    const is_social_accounts_set = auth.user[0].userprofile.social_accounts.length > 0

    const newData = posts_cal_events?.map(event => ({
        ...event,
        'title': event.title,
        id: event.post_id,
        start: new Date(event.start),
        end: new Date(event.end),
    }));


    let newPull = false;
    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + 'templates/';
        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                setTemplates(data);
            })
            .catch(error => console.error(error));
    }, [newPull]);

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + 'post/posts/';
        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                setPosts(data);
                console.log(data)
            })
            .catch(error => console.error(error));
    }, [newPull]);

    // Define a custom event wrapper component
    const ColoredEventWrapper = ({children, event}) => {
        return React.cloneElement(children, {
            style: {
                ...children.props.style,
                // backgroundColor:
                border: '2px solid red'
            },
        });
    };

    const CustomEvent = ({event}) => (
        <Card sx={{display: 'flex'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <CardContent sx={{flex: '1 0 auto'}}>
                    <Typography component="div" variant="h5">
                        Live From Space
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{color: 'text.secondary'}}
                    >
                        Mac Miller
                    </Typography>
                </CardContent>
                <Box sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>
                    {/*<IconButton aria-label="previous">*/}
                    {/*    {theme.direction === 'rtl' ? <SkipNextIcon/> : <SkipPreviousIcon/>}*/}
                    {/*</IconButton>*/}
                    {/*<IconButton aria-label="play/pause">*/}
                    {/*    <PlayArrowIcon sx={{height: 38, width: 38}}/>*/}
                    {/*</IconButton>*/}
                    {/*<IconButton aria-label="next">*/}
                    {/*    {theme.direction === 'rtl' ? <SkipPreviousIcon/> : <SkipNextIcon/>}*/}
                    {/*</IconButton>*/}
                </Box>
            </Box>
            <CardMedia
                component="img"
                sx={{width: 151}}
                image="/static/images/cards/live-from-space.jpg"
                alt="Live from space album cover"
            />
        </Card>
        // <div style={{backgroundColor: event.is_posted ? 'blue' : 'red'}} onClick={() => {
        //     alert(event.id)
        // }}>
        //     <strong>{event.title}</strong>
        //     <p>{event.description}</p>
        // </div>
    );

    function EventWrapper({event}) {
        // const classes = useStyles(); // Use the styles
        return (
            //*<span className={classes.eventWrapper}>*/}
            <span>
            <strong>{event.title}</strong>
            <p>{event.post_text}</p>
          </span>
        );
    }

    const {components} = useMemo(
        () => ({
            components: {
                event: CustomEvent,
            },
        }),
        []
    );
    const ColoredDateCellWrapper = ({children}) =>
        React.cloneElement(React.Children.only(children), {
            style: {
                backgroundColor: 'lightblue',
            },
        })
    return (
        <div>
            <Typography variant="h1" component='h1' gutterBottom>
                Welcome Back!
            </Typography>

            <Grid container spacing={3}>
                {posts_cal_events?.length > 0 ? (
                    <>
                        <Grid item xs={12}>
                            <Calendar
                                localizer={localizer}
                                events={newData}
                                components={{
                                    event: CustomEvent,
                                    eventWrapper: ColoredEventWrapper
                                }}
                                header={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                                }}
                                defaultView="month"
                                startAccessor="start"
                                defaultDate={new Date}
                                endAccessor="end"
                                views={['day', 'week', 'month']}
                                style={{height: 500}}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} height="60vh">
                            <Grid container display="flex" justifyContent="center">
                                <MessageWrapper width="100%">
                                    {templates.length > 0 ? (
                                        <>
                                        {
                                            is_social_accounts_set ? (
                                                <>
                                                    <Typography variant="h5">
                                                        Create your first <Link to="/post/create">post</Link> to see how
                                                        Solo Social can save you time
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography variant="h5">
                                                        Head over to your <Link to="/profile">profile</Link> to connect
                                                        to your social media platforms.
                                                    </Typography>
                                                </>
                                            )
                                        }
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="h5">
                                                Create your first <Link to="/editor">template</Link> and a post to see
                                                the magic of Solo Social
                                            </Typography>
                                        </>
                                    )}
                                </MessageWrapper>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
}

export default DashboardPage;
