import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import {Box, Button, CircularProgress, Slider, Typography} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const VideoEditor = ({onVideoProcess, childId, initialVideoUrl}) => {
    const [video, setVideo] = useState(null);
    const [processedVideoUrl, setProcessedVideoUrl] = useState(initialVideoUrl || null);
    const [duration, setDuration] = useState(0);
    const [trim, setTrim] = useState([0, 0]);
    const [playing, setPlaying] = useState(false);
    const [processing, setProcessing] = useState(false);
    const playerRef = useRef(null);
    // const videoUrl = processedVideoUrl || (video ? URL.createObjectURL(video) : null);

    const videoUrl = useMemo(() => {
        if (processedVideoUrl) return processedVideoUrl;
        if (video) return URL.createObjectURL(video);
        return null;
    }, [video, processedVideoUrl]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setVideo(file);
        setProcessedVideoUrl(null);
    };

    const handleDuration = (duration) => {
        setDuration(duration);
        setTrim([0, duration]);
    };

    const handleTrimChange = (event, newValue) => {
        setTrim(newValue);
        if (playerRef.current) {
            playerRef.current.seekTo(newValue[0], 'seconds');
        }
    };

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleProgress = useCallback(({playedSeconds}) => {
        if (playedSeconds >= trim[1]) {
            setPlaying(false);
            playerRef.current.seekTo(trim[0], 'seconds');
        }
    }, [trim]);

    const handleVideoProcess = async () => {
        setProcessing(true);
        const formData = new FormData();
        formData.append('video', video);
        formData.append('start', trim[0]);
        formData.append('end', trim[1]);

        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'templates/create/video/', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                },
            });
            if (!response.ok) {
                throw new Error('Video processing failed');
            }
            const data = await response.json();
            setProcessedVideoUrl(data.url);
            onVideoProcess(childId, data.url, data.duration);
        } catch (error) {
            console.error('Error processing video:', error);
            // You might want to show an error message to the user here
        } finally {
            setProcessing(false);
        }
    };

    useEffect(() => {
        // Reset player when video changes
        if (playerRef.current) {
            playerRef.current.seekTo(0);
        }
    }, [videoUrl]);

    // Cleanup function to revoke object URL
    useEffect(() => {
        return () => {
            if (video) URL.revokeObjectURL(URL.createObjectURL(video));
        };
    }, [video]);

    return (
        <Box>
            <input
                accept="video/*"
                style={{display: 'none'}}
                id="raised-button-file"
                type="file"
                onChange={handleFileUpload}
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" startIcon={<CloudUploadIcon/>}>
                    Upload Video
                </Button>
            </label>
            {videoUrl && (
                <>
                    <Box my={2} position="relative">
                        <ReactPlayer
                            ref={playerRef}
                            url={videoUrl}
                            controls
                            width="100%"
                            height="auto"
                            onDuration={handleDuration}
                            playing={playing}
                            onProgress={handleProgress}
                        />
                        {processing && (
                            <Box
                                position="absolute"
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                bgcolor="rgba(0,0,0,0.5)"
                            >
                                <CircularProgress/>
                            </Box>
                        )}
                    </Box>
                    <Box my={2}>
                        <Typography gutterBottom>Trim Video</Typography>
                        <Slider
                            value={trim}
                            onChange={handleTrimChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={0}
                            max={duration}
                            step={0.1}
                            disabled={processing}
                        />
                        <Box display="flex" justifyContent="space-between">
                            <Typography>Start: {trim[0].toFixed(2)}s</Typography>
                            <Typography>End: {trim[1].toFixed(2)}s</Typography>
                        </Box>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePlayPause}
                        startIcon={playing ? <PauseIcon/> : <PlayArrowIcon/>}
                        disabled={processing}
                    >
                        {playing ? 'Pause' : 'Preview Clip'}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleVideoProcess}
                        sx={{ml: 2}}
                        disabled={processing || processedVideoUrl !== null}
                    >
                        {processing ? <CircularProgress size={24}/> : 'Process Video'}
                    </Button>
                </>
            )}
        </Box>
    );
};

export default VideoEditor;