import * as React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Box, StyledEngineProvider, ThemeProvider} from '@mui/material';
import './index.css'

import LoginForm from './pages/LoginPage';

import Footer from "./layout/Footer";
import SocialMediaPostDetailsPage from "./pages/SocialMediaPostDetailsPage";
import LandingPage from "./pages/LandingPage";
import OnboardingFlowPage from "./pages/OnboardingFlowPage";
import ProfilePage from "./pages/ProfilePage";
import TopNavBar from "./layout/TopNavBar";
import Register from "./pages/Register";
import TemplateEditorPage from "./pages/TemplateEditorPage";
import DashboardPage from "./pages/DashboardPage";
import TemplateGeneratorPage from "./pages/TemplateGeneratorPage";
import SocialConnectionPage from "./pages/SocialConnectionPage";
import CheckEmailPage from "./pages/CheckEmailPage";
import {PrivateRoutes} from "./providers/PrivateRouteProvider";
import {useAuth} from "./providers/AuthProvider";

import PricingPage from "./pages/PricingPage";
import PaidPage from "./pages/PaidPage";
import StatusAlert from "./components/Alerts";
import NewPostPage from "./pages/NewPost";

import theme from './theme'
// import makeStyles from '@mui/styles/makeStyles';
import SocialConnectionLinkedInPage from "./pages/SocialConnectionLinkedInPage";
import styled from '@emotion/styled';
import ConfirmEmailPage from "./pages/ConfirmEmailPage";
import SocialConnectionInstagramPage from "./pages/SocialConnectionInstagramPage";
import SocialConnectionTwitterPage from "./pages/SocialConnectionTwitterPage";

const AppWrapper = styled.div`
    //padding: 20px 24px;
    padding-top: 24px;
    //max-height: 100%;
    //margin-left: 0;
    //margin-top: 20px;
`;

export default function App() {
    const {user} = useAuth();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <div>
                        <StatusAlert/>
                        <TopNavBar/>
                        <main id="main-content">
                            <AppWrapper>
                                <div>
                                    <Box sx={{my: 4}}>
                                        <Routes>
                                            <Route exact path="/" element={<LandingPage/>}/>
                                            <Route exact path="/login" element={<LoginForm/>}/>
                                            <Route exact path="/signup" element={<Register/>}/>
                                            <Route exact path="/onboarding" element={<OnboardingFlowPage/>}/>
                                            <Route exact path="/pricing" element={<PricingPage/>}/>
                                            <Route exact path="/paid" element={<PaidPage/>}/>
                                            <Route exact path="/check-email" element={<CheckEmailPage/>}/>
                                            <Route exact path="/confirm-email/:token" element={<ConfirmEmailPage/>}/>
                                            {/*<Route element={<PrivateRoutes>}>*/}
                                            <Route exact path="/post/create" element={<PrivateRoutes
                                                userDetail={user?.[0]}><NewPostPage/></PrivateRoutes>}/>
                                            {/*<Route exact path="/testing/post" element={<PrivateRoutes*/}
                                            {/*    userDetail={user?.[0]}><SocialMediaPostsPage/></PrivateRoutes>}/>*/}
                                            <Route exact path="/editor" element={<PrivateRoutes
                                                userDetail={user?.[0]}><TemplateEditorPage/></PrivateRoutes>}/>
                                            {/*<Route exact path="/social" element={<PrivateRoutes*/}
                                            {/*    userDetail={user?.[0]}><SocialMediaCreatePage/></PrivateRoutes>}/>*/}
                                            <Route exact path="/post/:id" element={<PrivateRoutes
                                                userDetail={user?.[0]}><SocialMediaPostDetailsPage/></PrivateRoutes>}/>
                                            <Route exact path="/profile" element={<PrivateRoutes
                                                userDetail={user?.[0]}><ProfilePage/></PrivateRoutes>}/>
                                            <Route exact path="/dashboard" element={<PrivateRoutes
                                                userDetail={user?.[0]}><DashboardPage/></PrivateRoutes>}/>
                                            <Route exact path="/generate" element={<PrivateRoutes
                                                userDetail={user?.[0]}><TemplateGeneratorPage/></PrivateRoutes>}/>
                                            <Route exact path="/connect/socials" element={<PrivateRoutes
                                                userDetail={user?.[0]}><SocialConnectionPage/></PrivateRoutes>}/>
                                            <Route exact path="/connect/linkedin/callback" element={<PrivateRoutes
                                                userDetail={user?.[0]}><SocialConnectionLinkedInPage/></PrivateRoutes>}/>
                                            <Route exact path="/connect/ig/callback" element={<PrivateRoutes
                                                userDetail={user?.[0]}><SocialConnectionInstagramPage/></PrivateRoutes>}/>
                                            <Route exact path="/connect/twitter/callback" element={<PrivateRoutes
                                                userDetail={user?.[0]}><SocialConnectionTwitterPage/></PrivateRoutes>}/>
                                            {/*</Route>*/}
                                        </Routes>
                                    </Box>
                                </div>
                            </AppWrapper>
                        </main>
                        <Footer/>
                    </div>
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
